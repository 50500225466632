import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useCallback,
} from "react";
import jwt_decode from "jwt-decode";
import { httpClient } from "../util/http";

const CurrentUserContext = createContext<
  | {
      currentUser: User;
      setCurrentUser: Dispatch<SetStateAction<User>>;
      isAuthenticated: boolean;
      setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
      setTokenInCache: (token: string) => Promise<void>;
      logout: () => void;
      isValidating: boolean;
      isAdmin: boolean;
    }
  | undefined
>(undefined);

const nonAdminUsers = [
  "cristian.rdz.1097@gmail.com",
  "saleh.iqbal09@gmail.com",
];

function CurrentUserProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User>({
    email: "",
    exp: 0,
    iat: 0,
    firstName: "",
    lastName: "",
    userId: 0,
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const [isValidating, setIsValidating] = useState<boolean>(true);

  const [isAdmin, setIsAdmin] = useState<boolean>(true);

  const setTokenInCache = async (token: string) => {
    try {
      const user = await jwt_decode(token);
      localStorage.setItem("token", token);
      setCurrentUser(user as User);
      if (nonAdminUsers.includes((user as User).email)) {
        setIsAdmin(false);
      }
      setIsAuthenticated(true);
    } catch (e) {
      console.log(e);
      localStorage.removeItem("token");
      setCurrentUser({
        email: "",
        exp: 0,
        iat: 0,
        firstName: "",
        lastName: "",
        userId: 0,
      });
      setIsAuthenticated(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setCurrentUser({
      email: "",
      exp: 0,
      iat: 0,
      firstName: "",
      lastName: "",
      userId: 0,
    });
    setIsAuthenticated(false);
  };

  const validateToken = useCallback(async (token: string) => {
    setIsValidating(true);
    try {
      await httpClient.post("/api/public/users/validate-token", { token });
      setTokenInCache(token);
      setIsValidating(false);
    } catch (e) {
      console.log(e);
      logout();
      setIsValidating(false);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      validateToken(token);
    } else {
      setIsValidating(false);
    }
  }, [validateToken]);

  const contextValue = {
    currentUser,
    setCurrentUser,
    isAuthenticated,
    setIsAuthenticated,
    setTokenInCache,
    logout,
    isValidating,
    isAdmin,
  };

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
}

function useCurrentUser() {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error("useCurrentUser must be used within a CurrentUserProvider");
  }
  return context;
}

export { CurrentUserProvider, useCurrentUser };
